<template>
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="vx-card p-4">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="flex flex-wrap items-center justify-end" v-if="infoOrganization && infoOrganization.fullAddress">
                            <vs-button @click="exportFilePdf" icon-pack="feather" icon="icon-download"  class="ml-auto" type="border">Tải về</vs-button>
                        </div>
                    </div>
                </div>
                <vs-table stripe :max-items="10" pagination :data="minioStorageData" class="mt-4">
                    <template slot="thead">
                        <vs-th style="font-size:13px" class="text-center bg-primary text-white"  v-for="(item, itemIndex) in headerTable" :key="itemIndex">{{ item.title }}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <tbody>
                            <vs-tr class="hoverFlat" :key="indextr" v-for="(storage, indextr) in data">
                                <vs-td class="md:w-1/4"> {{ storage.organizationName }} </vs-td>
                                <vs-td class="md:w-1/4"> {{ storage.deviceId }} </vs-td>
                                <vs-td class="md:w-2/12"> {{ storage.createdDate }} </vs-td>
                                <vs-td class="md:w-2/12"> {{ storage.file }} </vs-td>
                                <vs-td class="md:w-2/12"> {{ storage.size }} </vs-td>
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </div>
        </div>
    </div>
</template>

<script>
import authConfig from '@/../auth_config.json';
export default {
    name: 'app',
    props: {
        minioStorageData: [],
        infoOrganization: {},
        totalData: 0
    },
    components: {
    },
    data(){
        return {
            headerTable: [
                {title: 'Tên tổ chức'},
                {title: 'Mã thiết bị'},
                {title: 'Thời gian'},
                {title: 'Số lượng file'},
                {title: 'Dung lượng'}
            ]
        }
    },
    methods: {
        exportFilePdf: function(){
            const payload = {
                minioStorageDataRequests: this.minioStorageData,
                info: this.infoOrganization,
                totalData: this.totalData
            }
            this.$vs.loading();
            this.$processor.post('/minio-storage/export-file-pdf', payload).then((response) => {
                this.$vs.loading.close();
                window.open(authConfig.processorDomain + "file/" + response.data + "?access_token="+ localStorage.getItem("access_token"), "_blank");
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        }
    },
    async created(){
    }
}
</script>

<style lang="scss">
.header-table {
    color: rgba(var(--vs-primary),1);
    background: rgba(47, 128, 237, 0.12);
    font-weight: bold;
}
</style>
